<template>
  <div>
    <van-nav-bar title="代客編輯商務卡片" right-text="關閉" @click-right="$router.push('/')" />
    <table id="auth-list">
      <tr>
        <th>授權會員</th>
        <th>授權時間</th>
        <th>操作</th>
      </tr>
      <tr v-for="v of authList" :key="v.id">
        <td>{{v.user_id}}</td>
        <td>{{v.auth_time}}</td>
        <td @click="handleEdit(v.user_id)">編輯</td>
      </tr>
    </table>
    
  </div>
</template>

<script setup>
import { onMounted , ref } from "vue";

import { getAuthList } from '@/api';
import router from "@/router";

const authList = ref([]);

onMounted(async()=>{
  let res = await getAuthList();
  if(res.code===200){
    authList.value = res.data;
  }
})

const handleEdit = (user_id)=>{
  router.push({path:'/auth/edit',query:{user_id: user_id}});
}

</script>

<style lang="less" scoped>
#auth-list{
  width: 100%;
  td,th{
    border: 1px solid #ddd;
    padding: 3px;
  }
  tr:nth-child(even){
    background-color: #f2f2f2;
  }
}
</style>